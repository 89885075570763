import { createGlobalStyle } from 'styled-components'
import tw from 'twin.macro'

import fonts from './fonts'

export const GlobalCustomStyles = createGlobalStyle`
  ${fonts}

  body {
    ${tw`antialiased font-ringside-narrow`}
  }

  svg {
    width: 24px;
  }
`
export default GlobalCustomStyles
