import { css } from 'styled-components'

const fonts = css`
  // Monument Grotesk

  @font-face {
    font-family: 'Monument Grotesk';
    src: local('Monument Grotesk'),
      url('/fonts/MonumentGrotesk/MonumentGrotesk-Regular.woff') format('woff');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Monument Grotesk';
    src: local('Monument Grotesk'),
      url('/fonts/MonumentGrotesk/MonumentGrotesk-Medium.woff') format('woff');
    font-weight: 500;
  }

  @font-face {
    font-family: 'Monument Grotesk';
    src: local('Monument Grotesk'),
      url('/fonts/MonumentGrotesk/MonumentGrotesk-Bold.woff') format('woff');
    font-weight: bold;
  }

  // Ringside Condensed

  @font-face {
    font-family: 'Ringside Condensed';
    src: local('Ringside Condensed'),
      url('/fonts/Ringside/Condensed/RingsideCondensed-Black.otf')
        format('opentype');
    font-weight: 900;
  }

  @font-face {
    font-family: 'Ringside Condensed';
    src: local('Ringside Condensed'),
      url('/fonts/Ringside/Condensed/RingsideCondensed-Bold.otf')
        format('opentype');
    font-weight: bold;
  }

  @font-face {
    font-family: 'Ringside Condensed';
    src: local('Ringside Condensed'),
      url('/fonts/Ringside/Condensed/RingsideCondensed-Book.otf')
        format('opentype');
    font-weight: 350;
  }

  @font-face {
    font-family: 'Ringside Condensed';
    src: local('Ringside Condensed'),
      url('/fonts/Ringside/Condensed/RingsideCondensed-Light.otf')
        format('opentype');
    font-weight: 300;
  }

  @font-face {
    font-family: 'Ringside Condensed';
    src: local('Ringside Condensed'),
      url('/fonts/Ringside/Condensed/RingsideCondensed-Medium.otf')
        format('opentype');
    font-weight: 500;
  }

  // Ringside Narrow

  @font-face {
    font-family: 'Ringside Narrow';
    src: local('Ringside Narrow'),
      url('/fonts/Ringside/Narrow/RingsideNarrow-Black.otf') format('opentype');
    font-weight: 900;
  }

  @font-face {
    font-family: 'Ringside Narrow';
    src: local('Ringside Narrow'),
      url('/fonts/Ringside/Narrow/RingsideNarrow-Bold.otf') format('opentype');
    font-weight: bold;
  }

  @font-face {
    font-family: 'Ringside Narrow';
    src: local('Ringside Narrow'),
      url('/fonts/Ringside/Narrow/RingsideNarrow-Book.otf') format('opentype');
    font-weight: 350;
  }

  @font-face {
    font-family: 'Ringside Narrow';
    src: local('Ringside Narrow'),
      url('/fonts/Ringside/Narrow/RingsideNarrow-Light.otf') format('opentype');
    font-weight: 300;
  }

  @font-face {
    font-family: 'Ringside Narrow';
    src: local('Ringside Narrow'),
      url('/fonts/Ringside/Narrow/RingsideNarrow-Medium.otf') format('opentype');
    font-weight: 500;
  }

  // Ringside Regular

  @font-face {
    font-family: 'Ringside Regular';
    src: local('Ringside Regular'),
      url('/fonts/Ringside/Regular/RingsideRegular-Black.otf')
        format('opentype');
    font-weight: 900;
  }

  @font-face {
    font-family: 'Ringside Regular';
    src: local('Ringside Regular'),
      url('/fonts/Ringside/Regular/RingsideRegular-Bold.otf') format('opentype');
    font-weight: bold;
  }

  @font-face {
    font-family: 'Ringside Regular';
    src: local('Ringside Regular'),
      url('/fonts/Ringside/Regular/RingsideRegular-Book.otf') format('opentype');
    font-weight: 350;
  }

  @font-face {
    font-family: 'Ringside Regular';
    src: local('Ringside Regular'),
      url('/fonts/Ringside/Regular/RingsideRegular-Light.otf')
        format('opentype');
    font-weight: 300;
  }

  @font-face {
    font-family: 'Ringside Regular';
    src: local('Ringside Regular'),
      url('/fonts/Ringside/Regular/RingsideRegular-Medium.otf')
        format('opentype');
    font-weight: 500;
  }
`

export default fonts
